import React from 'react'
import '../../footer/footer.css'

const FooterDE = () => {
  return (
    <section id="footer">
    
    </section>
  )
}

export default FooterDE
