import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <section id="footer">
      
    </section>
  )
}

export default Footer
